.GeneralInfo {
    border-bottom: 1px solid getBorderColor('standard');
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;

    & .col {
        
        &:first-of-type {
            text-align: left;
        }
        &:last-of-type {
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    & .name {
        font-size: getFontSize('largest');
    }

    & .under-title {
        color: getTextColor('grey');
    }
}