@import './PermissionsTable';
@import './PasswordResetConfirmationModal/PasswordResetConfirmationModal';
@import './EditCharactersByUser/EditCharactersByUser';

.Users {

    & .top-nav, .top-nav span {
        display: flex;
        gap: 5px;
        justify-content: space-between;
    }

    & .staff-list {
        display: grid;
        gap: 10px;
        margin-top: 15px;

        @include adaptive-grid();

        & .staff-item {
            padding: 10px;
            padding-top: 0;
            background: getBackgroundColor('section');
            border-radius: 20px;
            border: 1px solid getBorderColor('standard');

            & .bottom-nav {
                display: flex;
                justify-content: space-between;
                border-top: 1px solid getBorderColor('standard');
                padding-top: 10px;

                & .staff-characters {
                    & .character {
                        display: flex;
                        gap: 5px;
                    }

                    & details {
                        display: flex;

                        & summary {
                            color: getPrimaryColor('popping');
                        }
                    }
                }

                & .danger-zone {
                    display: flex;
                    align-items: start;
                    gap: 5px;
                }
            }
        }
    }
}