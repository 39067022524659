.Form {
    background: getBackgroundColor('section');
    border-bottom: 1px solid getBorderColor('standard');
    padding: 10px;

    & input, & textarea {
        border-radius: 10px !important;
        padding: 3px 5px;
    }

    & .top-nav {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;

        & .col {
            display: flex;
            gap: 5px;   
            align-items: center;
        }
    }
    
    & .title {
        font-size: getFontSize('larger');
        color: getPrimaryColor('popping'); 
    }

    & .form {
        display: flex;
        gap: 20px;
        width: 100%;

        & .divider {
            border-right: 1px solid getBorderColor('standard');
        }

        & .text {
            width: 100%;
            grid-row-gap: 10px;
            grid-column-gap: 20px;

            & section {
                margin: 5px 0;

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        & .two-columns {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        & .MultiToggle {
            justify-content: start;
        }

        & .media {
            width: 300px;

            & .profile-picture {
                margin-bottom: 5px;
            }
            
            & .image-placeholder {
                background: getBackgroundColor('primary');
                border: 1px solid getBorderColor('standard');
                width: 300px;

                & .image {
                    width: 100%;
                    margin-bottom: -6px;
                }
            }

            & .Button {
                width: 100%;
            }

            & .info {
                margin-top: 5px;
                color: getPrimaryColor('popping');
                font-size: getFontSize('standard');

                & li {
                    list-style: none;
                }
            }
        }
            
        & .name {
        
            & input {
                width: 100%;
                font-size: 16px;
            }
        }
        & .bio {
            & textarea {
                resize: vertical;
                width: 50%;
                min-height: 200px;  
            }
        }

        & .bottom-nav {
            display: flex;
            justify-content: end;
        }
    }
}