@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
button.link {
  background: none;
  border: none;
  outline: none;
  text-decoration: underline;
}
button.link:hover {
  cursor: pointer;
}
button.tooltip {
  color: #fff;
}
button.tooltip.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
button.tooltip:hover button.tooltiptext {
  visibility: visible;
}

.destructive {
  background: rgb(168, 63, 63) !important;
  color: #fff;
}

.constructive {
  background: rgb(86, 119, 86) !important;
  color: #fff;
}

.progressive {
  background: rgb(173, 164, 88) !important;
}

.informative {
  background: blue !important;
}

.neutral {
  background: #373737 !important;
  color: #fff;
}

.dark {
  background: #222 !important;
}

button:hover, select:hover, option:hover {
  cursor: pointer;
  filter: brightness(1.2);
}
button:active, select:active, option:active {
  box-shadow: inset 0px 1px 4px 1px black !important;
}

.login-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.login-wrapper .EnvironmentNotice {
  z-index: 3;
}
.login-wrapper .CookieBanner {
  z-index: 3;
}
.login-wrapper .video-info {
  z-index: 3;
  padding: 5px;
  color: white;
}
.login-wrapper .video-info a, .login-wrapper .video-info a:visited, .login-wrapper .video-info a:focus {
  color: inherit;
}
.login-wrapper .Login {
  position: relative;
  z-index: 1;
}
.login-wrapper .Login .background {
  position: fixed;
  filter: brightness(50%);
  top: 0;
  left: 0;
  overflow: hidden;
}
.login-wrapper .Login .background video {
  z-index: 2;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}
.login-wrapper .Login .login-box {
  display: grid;
  place-items: center;
  position: relative;
  height: 100%;
}
.login-wrapper .Login .login-box .form-container {
  display: grid;
  gap: 10px;
  background: rgba(44, 44, 44, 0.8549019608);
  color: #fff;
  box-shadow: 0px 0px 1px 0px black;
  border: 1px solid #0e0e0e;
  border-radius: 5px;
  padding: 10px;
}
.login-wrapper .Login .login-box .form-container .header {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  text-align: center;
}
.login-wrapper .Login .login-box .form-container form {
  display: grid;
  gap: 5px;
}
.login-wrapper .Login .login-box .form-container form label {
  display: flex;
  justify-content: space-between;
}
.login-wrapper .Login .login-box .form-container form input {
  background: #222222;
  color: white;
  border: 1px solid #0e0e0e;
}
.login-wrapper .Login .login-box .form-container .footer {
  border-top: 1px solid black;
  padding-top: 5px;
}

.GeneralInfo {
  border-bottom: 1px solid #0e0e0e;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.GeneralInfo .col:first-of-type {
  text-align: left;
}
.GeneralInfo .col:last-of-type {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.GeneralInfo .name {
  font-size: 32px;
}
.GeneralInfo .under-title {
  color: grey;
}

.VisitsPerOpening {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 50px;
  height: 100%;
}
.VisitsPerOpening .graph {
  height: 100%;
}

.VenueStats .graph {
  width: 99%;
}

.Characters {
  display: flex;
  gap: 5px;
}

.Form {
  background: rgba(34, 34, 34, 0.4509803922);
  border-bottom: 1px solid #0e0e0e;
  padding: 10px;
}
.Form input, .Form textarea {
  border-radius: 10px !important;
  padding: 3px 5px;
}
.Form .top-nav {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.Form .top-nav .col {
  display: flex;
  gap: 5px;
  align-items: center;
}
.Form .title {
  font-size: 24px;
  color: rgb(196, 177, 166);
}
.Form .form {
  display: flex;
  gap: 20px;
  width: 100%;
}
.Form .form .divider {
  border-right: 1px solid #0e0e0e;
}
.Form .form .text {
  width: 100%;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
}
.Form .form .text section {
  margin: 5px 0;
}
.Form .form .text section:first-of-type {
  margin-top: 0;
}
.Form .form .text section:last-of-type {
  margin-bottom: 0;
}
.Form .form .two-columns {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.Form .form .MultiToggle {
  justify-content: start;
}
.Form .form .media {
  width: 300px;
}
.Form .form .media .profile-picture {
  margin-bottom: 5px;
}
.Form .form .media .image-placeholder {
  background: #222222;
  border: 1px solid #0e0e0e;
  width: 300px;
}
.Form .form .media .image-placeholder .image {
  width: 100%;
  margin-bottom: -6px;
}
.Form .form .media .Button {
  width: 100%;
}
.Form .form .media .info {
  margin-top: 5px;
  color: rgb(196, 177, 166);
  font-size: 14px;
}
.Form .form .media .info li {
  list-style: none;
}
.Form .form .name input {
  width: 100%;
  font-size: 16px;
}
.Form .form .bio textarea {
  resize: vertical;
  width: 50%;
  min-height: 200px;
}
.Form .form .bottom-nav {
  display: flex;
  justify-content: end;
}

.FirstCharacterPrompt {
  height: 100vh;
  display: grid;
  place-items: center;
}
.FirstCharacterPrompt .container {
  display: grid;
  gap: 20px;
}
.FirstCharacterPrompt .container .row {
  display: grid;
  justify-items: center;
}
.FirstCharacterPrompt .container .headline {
  color: grey;
  font-size: 32px;
}

.Character .top-bar, .Character .addendum-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0e0e0e;
  margin-top: 5px;
  padding-bottom: 5px;
}
.Character .top-bar .name, .Character .addendum-bar .name {
  font-size: 32px;
}
.Character .top-bar .under-title, .Character .addendum-bar .under-title {
  color: grey;
}
.Character .top-bar span:last-of-type, .Character .addendum-bar span:last-of-type {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Character .top-bar .col, .Character .addendum-bar .col {
  display: flex;
  align-items: center;
  gap: 5px;
}
.Character .switch {
  display: flex;
  gap: 5px;
  align-items: center;
}

.Account {
  width: 100%;
  display: grid;
  justify-items: center;
  background: rgba(34, 34, 34, 0.4509803922);
  margin-top: 15px;
  padding: 15px 0 15px 0;
}
.Account .container {
  display: grid;
}
.Account .container .item {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.Account .info {
  color: rgb(196, 177, 166);
}

.ModalManager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.521);
}
.ModalManager .modal-display {
  position: absolute;
}

.NotificationManager {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
  display: grid;
  justify-items: center;
  gap: 2px;
}
.NotificationManager .notification {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #0e0e0e;
  box-shadow: 0px 0px 1px 0px black;
}
.NotificationManager .notification .title-bar {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.NotificationManager .notification .title-bar .title {
  font-weight: bold;
}
.NotificationManager .notification .title-bar .close-button {
  background: none;
  border: none;
  color: white;
}
.NotificationManager .notification.error {
  background: rgb(168, 63, 63);
  color: white;
}
.NotificationManager .notification.warning {
  background: rgb(173, 164, 88);
  color: black;
}
.NotificationManager .notification.warning .close-button {
  color: black;
}
.NotificationManager .notification.confirmation {
  background: rgb(86, 119, 86);
  color: white;
}
.NotificationManager .notification.confirmation .close-button {
  color: white;
}

.PermissionsTable {
  color: #fff;
}
.PermissionsTable table {
  border-collapse: collapse;
}
.PermissionsTable table th {
  padding: 0 5px;
}
.PermissionsTable table td {
  border: 1px solid rgb(196, 177, 166);
  padding: 5px;
  text-align: center;
}
.PermissionsTable table td.action {
  text-align: left;
}

.PasswordResetConfirmationModal {
  color: #fff;
}

.EditCharactersByUser {
  display: grid;
  gap: 5px;
}
.EditCharactersByUser .character, .EditCharactersByUser nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 5px;
}

.Users .top-nav, .Users .top-nav span {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.Users .staff-list {
  display: grid;
  gap: 10px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 1600px) {
  .Users .staff-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1000px) {
  .Users .staff-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.Users .staff-list .staff-item {
  padding: 10px;
  padding-top: 0;
  background: rgba(34, 34, 34, 0.4509803922);
  border-radius: 20px;
  border: 1px solid #0e0e0e;
}
.Users .staff-list .staff-item .bottom-nav {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #0e0e0e;
  padding-top: 10px;
}
.Users .staff-list .staff-item .bottom-nav .staff-characters .character {
  display: flex;
  gap: 5px;
}
.Users .staff-list .staff-item .bottom-nav .staff-characters details {
  display: flex;
}
.Users .staff-list .staff-item .bottom-nav .staff-characters details summary {
  color: rgb(196, 177, 166);
}
.Users .staff-list .staff-item .bottom-nav .danger-zone {
  display: flex;
  align-items: start;
  gap: 5px;
}

.Staff {
  margin-top: 10px;
}

.Menu .top-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}
.Menu .categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media screen and (min-width: 1600px) {
  .Menu .categories {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1000px) {
  .Menu .categories {
    grid-template-columns: repeat(1, 1fr);
  }
}
.Menu .categories .category .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0e0e0e;
  margin-bottom: 5px;
}
.Menu .categories .category .title .name {
  color: rgb(196, 177, 166);
  font-size: 24px;
}
.Menu .categories .category .title nav {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.Menu .categories .category .items {
  display: grid;
  gap: 5px;
}
.Menu .categories .category .items .item, .Menu .categories .category .items .item nav {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.Sidebar {
  background: #444C44;
  border-radius: 5px 0 0 5px;
  border-right: 1px solid #0e0e0e;
  display: grid;
}
.Sidebar .toggle-overlay {
  padding: 5px;
}
.Sidebar .toggle-overlay .Button {
  padding: 5px;
}
@media screen and (max-width: 800px) {
  .Sidebar .Navigation {
    display: none;
  }
}

.Navigation {
  padding: 10px;
}
.Navigation .Button {
  font-size: 16px;
  font-weight: bold;
  gap: 10px;
}
.Navigation .Button img {
  max-height: 28px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}
.Navigation nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}
.Navigation nav section {
  display: grid;
}
.Navigation nav section .title {
  color: #fff;
  text-align: center;
  margin-bottom: 5px;
  display: grid;
  border-bottom: 1px solid rgba(0, 0, 0, 0.493);
  padding-bottom: 2px;
  margin-bottom: 10px;
}
.Navigation nav section .content {
  display: grid;
  gap: 5px;
}
.Navigation nav section .content .info {
  font-size: 14px;
}
.Navigation nav section .content .info.error {
  color: rgb(168, 63, 63);
  font-weight: bold;
}

.Overlay {
  position: fixed;
  background: #222222;
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
  display: grid;
  grid-template-rows: auto 1fr;
}
.Overlay .toggle-overlay {
  padding: 5px;
}
.Overlay .toggle-overlay .Button {
  padding: 5px;
}
.Overlay .content {
  display: grid;
  overflow-y: auto;
}
@media screen and (min-width: 800px) {
  .Overlay {
    display: none;
  }
}

.Dashboard {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
}
.Dashboard .environment-notice {
  background: yellow;
}
.Dashboard .environment-notice .text {
  padding: 2px 5px;
}
.Dashboard .kofi-link img {
  height: 30px;
}
.Dashboard .wrapper {
  background: rgba(44, 44, 44, 0.8549019608);
  color: #fff;
  display: grid;
  grid-template-columns: auto 1fr;
}
.Dashboard .wrapper main {
  padding: 10px;
  border-radius: 0 5px 5px 0;
  display: grid;
  grid-gap: getPadding("standard");
  grid-template-rows: min-content auto;
  overflow-y: auto;
}
.Dashboard .wrapper main section {
  background: getBackground("primary");
}

.About {
  display: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  color: #fff;
  display: grid;
  place-items: center;
}
.About .image {
  grid-area: img;
}
.About .image img {
  width: 100%;
}
.About .wrapper {
  max-width: 1000px;
  display: grid;
  gap: 25px;
  grid-template-areas: "head head head head" "devs devs contr contr" "thank thank thank thank";
}
.About .wrapper .title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(196, 177, 166);
}
.About .wrapper .name {
  font-weight: bold;
}
.About .wrapper .highlighted {
  color: rgb(173, 0, 0);
}
.About .wrapper header {
  grid-area: head;
  padding-bottom: 20px;
  border-bottom: 1px solid;
}
.About .wrapper header img {
  float: left;
  width: 200px;
  margin: 0 10px 0 0;
}
.About .wrapper #development {
  grid-area: devs;
}
.About .wrapper #contributions {
  grid-area: contr;
}
.About .wrapper #thanks {
  grid-area: thank;
  padding-top: 20px;
  border-top: 1px solid;
}
.About .wrapper .list {
  padding: 0 2px;
}
.About .wrapper .list .row {
  display: flex;
  justify-content: space-between;
}

.Tools {
  padding: 10px;
  color: #fff;
}

.Legal {
  color: #fff;
  padding: 20px;
}

.Sections {
  display: grid;
  justify-content: center;
}
.Sections .header nav {
  display: flex;
}
.Sections .section img {
  width: 1000px;
  height: 700px;
}

.PublicSeating {
  color: #fff;
}

.CloseButton {
  display: grid;
  place-items: center;
  background: rgba(80, 80, 80, 0.87);
  border: 1px solid #0e0e0e;
  margin: 0;
  padding: 0;
  box-shadow: inset 0px 0px 1px 0px black;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}
.CloseButton img {
  height: 16px;
  width: 16px;
}

.Button {
  border: 1px solid #0e0e0e;
  border-radius: 6px;
  padding: 2px 7px;
  font-size: 14px;
  color: #fff;
  text-shadow: 0.1em 0.1em 0.2em #222;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.Button.two-items {
  justify-content: space-between;
}
.Button.left {
  justify-content: start;
}
.Button img {
  margin: 0;
  padding: 0;
}
.Button.progressive {
  color: black;
  text-shadow: none;
}
.Button.inactive {
  background: none;
  pointer-events: none;
  opacity: 0.4;
}
.Button.loading {
  position: relative;
}

@keyframes ldio-y1lg4s4vfo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-y1lg4s4vfo div {
  left: 20px;
  top: 20px;
  position: absolute;
  animation: ldio-y1lg4s4vfo linear 1.2048192771s infinite;
  background: #ffffff;
  width: 6px;
  height: 12px;
  border-radius: 3px/6px;
  transform-origin: 3px 26px;
}

.ldio-y1lg4s4vfo div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1044176707s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.0040160643s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9036144578s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8032128514s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.702811245s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6024096386s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5020080321s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4016064257s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3012048193s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2008032129s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1004016064s;
  background: #ffffff;
}

.ldio-y1lg4s4vfo div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}

.loadingio-spinner-spinner-36d38ny93kh {
  width: 12px;
  height: 17px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-y1lg4s4vfo {
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-y1lg4s4vfo div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
.MultiToggleOption {
  background: #373737;
  color: #fff;
  font-size: 14px;
  padding: 3px 8px;
  border: 1px solid black;
  font-size: 14px;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}
.MultiToggleOption.active {
  background: #151515;
}
.MultiToggleOption.large {
  font-size: 18px;
  padding: 5px 10px;
}
.MultiToggleOption:first-child:last-child {
  border-radius: 10px;
}
.MultiToggleOption:first-child {
  border-radius: 10px 0 0 10px;
}
.MultiToggleOption:last-child {
  border-radius: 0 10px 10px 0;
}
.MultiToggleOption:hover {
  filter: none;
}

.MultiToggle {
  display: flex;
  justify-content: center;
}

.DropdownItem {
  background-color: #373737;
  border-radius: 10px;
}

.Dropdown {
  border: 1px solid #0e0e0e;
  border-radius: 6px;
  padding: 2px 17px 2px 7px;
  font-size: 14px;
  color: #fff;
  appearance: none;
  background: url("../assets/icons/dropdown-white.png") 96% center no-repeat;
  background-color: #222222;
}

.ImageUpload {
  display: grid;
  font-size: 14px;
}
.ImageUpload .file-warn {
  color: red;
}

.Toggle {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  border-radius: 10px;
}
.Toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.Toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222222;
  outline: 1px solid #0e0e0e;
  border-radius: 10px;
  box-shadow: inset 0px 0px 1px 0px black;
  transition: 200ms ease;
}
.Toggle .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 3px;
  background-color: rgb(236, 236, 236);
  border-radius: 50%;
}
.Toggle input:checked + .slider {
  background: rgb(86, 119, 86);
}
.Toggle input:focus + .slider {
  box-shadow: 0 0 1px grey;
}
.Toggle input:checked + .slider:before {
  transform: translateX(13px);
}

.Modal {
  display: grid;
  gap: 5px;
  color: #fff;
  background: rgba(44, 44, 44, 0.8549019608);
  z-index: 10;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #0e0e0e;
  box-shadow: 0px 0px 1px 0px black;
}
.Modal .body {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.Modal input, .Modal textarea {
  border-radius: 10px;
}
.Modal .headline, .Modal .message, .Modal .options {
  color: #fff;
}
.Modal .safety-message {
  color: rgb(196, 177, 166);
  margin-top: 10px;
  text-align: center;
}
.Modal .headline {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  gap: 10px;
}
.Modal .message {
  text-align: center;
}
.Modal .safety-prompt {
  display: flex;
  justify-content: center;
}
.Modal .safety-prompt input {
  width: 100%;
  text-align: center;
}
.Modal .options {
  display: flex;
  justify-content: space-between;
}

.CloseButton {
  display: grid;
  place-items: center;
  background: getBackgroundColor("quaternary");
  border: 1px solid getBorderColor("standard");
  margin: 0;
  padding: 0;
  box-shadow: getBoxShadow("inner");
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.CloseButton img {
  height: 16px;
  width: 16px;
}

.Button {
  border: 1px solid getBorderColor("standard");
  border-radius: 6px;
  padding: 2px 7px;
  font-size: getFontSize("standard");
  color: getTextColor("primary");
  text-shadow: 0.1em 0.1em 0.2em getPrimaryColor("dark");
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.Button.two-items {
  justify-content: space-between;
}

.Button.left {
  justify-content: start;
}

.Button img {
  margin: 0;
  padding: 0;
}

.Button.progressive {
  color: black;
  text-shadow: none;
}

.Button.inactive {
  background: none;
  pointer-events: none;
  opacity: 0.4;
}

.Button.loading {
  position: relative;
}

.SimpleForm {
  display: grid;
  padding: 15px 0 15px 0;
  width: 100%;
  color: #fff;
}
.SimpleForm img {
  max-width: 100%;
}
.SimpleForm .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #0e0e0e;
}
.SimpleForm .header .title {
  font-weight: bold;
  font-size: 18px;
  text-shadow: 0.1em 0.1em 0.2em black;
  color: rgb(196, 177, 166);
}
.SimpleForm .header nav {
  justify-self: end;
  display: flex;
  gap: 5px;
}
.SimpleForm .container {
  display: grid;
  gap: 5px;
}
.SimpleForm .container .item {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.SimpleForm .container .item.nav {
  justify-content: end;
  display: flex;
  gap: 5px;
}
.SimpleForm .container .item .value .image {
  display: grid;
  justify-items: center;
  gap: 5px;
}
.SimpleForm .container .item .value .image .warning {
  color: red;
}
.SimpleForm .container .item .value .image label {
  border: 1px solid #0e0e0e;
  border-radius: 6px;
  padding: 2px 7px;
  font-size: 14px;
  color: #fff;
  text-shadow: 0.1em 0.1em 0.2em #222;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.SimpleForm .container .item .value .image label.two-items {
  justify-content: space-between;
}
.SimpleForm .container .item .value .image label.left {
  justify-content: start;
}
.SimpleForm .container .item .value .image label img {
  margin: 0;
  padding: 0;
}
.SimpleForm .container .item .value .image label.progressive {
  color: black;
  text-shadow: none;
}
.SimpleForm .container .item .value .image label.inactive {
  background: none;
  pointer-events: none;
  opacity: 0.4;
}
.SimpleForm .container .item .value .image label.loading {
  position: relative;
}
.SimpleForm .container .item .value .image img {
  max-width: 150px;
  max-height: 200px;
}
.SimpleForm .container .item .value input, .SimpleForm .container .item .value textarea {
  border-radius: 10px;
  font-size: 14px;
  min-width: 250px;
}
.SimpleForm .container .item .value textarea {
  max-width: 100%;
  padding: 5px;
}
.SimpleForm .container .item input {
  text-align: center;
}
@media screen and (max-width: 800px) {
  .SimpleForm .container .item {
    display: grid;
    gap: 0;
    justify-content: center;
    justify-items: center;
  }
  .SimpleForm .container .item textarea {
    resize: vertical;
  }
}
.SimpleForm .container .info {
  color: rgb(196, 177, 166);
  font-size: 14px;
  margin-top: -5px;
}

.CopyField {
  padding: 3px 5px;
  color: black;
  border: 1px solid #0e0e0e;
  background: rgb(196, 177, 166);
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.CopyField .copy {
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-left: 5px;
  border-left: 1px solid #0e0e0e;
}

.IconButton {
  background: none;
  border: none;
  outline: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.IconButton:active {
  box-shadow: none !important;
}

.InstructionsEditor {
  padding: 5px;
  display: grid;
  gap: 5px;
}
.InstructionsEditor .instruction, .InstructionsEditor .col {
  display: flex;
  gap: 5px;
}
.InstructionsEditor .nav {
  align-items: center;
}
.InstructionsEditor .bottom-nav {
  display: flex;
  justify-content: end;
}

.LabelEditor {
  display: grid;
  gap: 5px;
}
.LabelEditor .labels {
  max-width: 250px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.LabelEditor nav {
  display: flex;
  gap: 5px;
}

.Input {
  border: 1px solid #0e0e0e;
  background: #222222;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
}
.Input:focus {
  outline: 1px solid grey;
}

.SearchFilter {
  display: flex;
  gap: 5px;
  align-items: center;
}

.CookieBanner {
  position: absolute;
  margin-bottom: 100px;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}
.CookieBanner .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 80%;
  color: white;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #0e0e0e;
  margin: 0 10px;
  background: rgba(44, 44, 44, 0.8549019608);
}
.CookieBanner .wrapper a {
  color: white;
}

.EnvironmentNotice {
  background: yellow;
  box-shadow: 0px 0px 1px 0px black;
}
.EnvironmentNotice .text {
  padding: 2px 5px;
}

body {
  margin: 0;
  background: rgba(10, 10, 10, 0.8549019608);
}

* {
  font-family: "Nunito", sans-serif;
}

textarea, input {
  border: 1px solid #0e0e0e;
  background: #222222;
  color: white;
}
textarea:focus, input:focus {
  outline: 1px solid grey;
}

.inactive {
  background: none;
  pointer-events: none;
  opacity: 0.4;
}

.blurred {
  filter: brightness(0.4);
}

.tooltip {
  font-size: 14px;
  text-decoration: underline;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  margin-left: 20px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
}
.tooltip:hover > .tooltiptext {
  visibility: visible;
}