.Input {
    border: 1px solid getBorderColor('standard');
    background: getBackgroundColor('primary');
    color: white;
    border-radius: 10px;
    font-size: getFontSize('standard');
    text-align: center;

    &:focus {
        outline: 1px solid grey;
    }
}