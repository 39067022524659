@import './CloseButton/CloseButton';

@mixin button {
    border: 1px solid getBorderColor('standard');
    border-radius: 6px;
    padding: 2px 7px;
    font-size: getFontSize('standard');
    color: getTextColor('primary');
    text-shadow: 0.1em 0.1em 0.2em getPrimaryColor('dark');
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &.two-items {
        justify-content: space-between;
    }

    &.left {
        justify-content: start;
    }

    & img {
        margin: 0;
        padding: 0;
    }

    &.progressive {
        color: black;
        text-shadow: none;
    }
    
    &.inactive {
        background: none;
        pointer-events: none;
        opacity: 0.4;
    }

    &.loading {
        position: relative;
    }
}

.Button {
    @include button;
}