.LabelEditor {
    display: grid;
    gap: 5px;

    & .labels {
        max-width: 250px;

        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    & nav {
        display: flex;
        gap: 5px;
    }
}