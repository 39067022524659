@keyframes ldio-y1lg4s4vfo {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-y1lg4s4vfo div {
  left: 20px;
  top: 20px;
  position: absolute;
  animation: ldio-y1lg4s4vfo linear 1.2048192771084336s infinite;
  background: #ffffff;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}.ldio-y1lg4s4vfo div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.104417670682731s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.004016064257028s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9036144578313252s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8032128514056224s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7028112449799196s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6024096385542168s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.502008032128514s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4016064257028112s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3012048192771084s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2008032128514056s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1004016064257028s;
  background: #ffffff;
}.ldio-y1lg4s4vfo div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}
.loadingio-spinner-spinner-36d38ny93kh {
  width: 12px;
  height: 17px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-y1lg4s4vfo {

  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-y1lg4s4vfo div { box-sizing: content-box; }
/* generated by https://loading.io/ */
