.Navigation {
    padding: 10px;

    & .Button {
        font-size: 16px;
        font-weight: bold;
        gap: 10px;

        & img {
            max-height: 28px;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
        }
    }

    & nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 10px;

        & section {
            display: grid;

            & .title {
                color: getTextColor('primary');
                text-align: center;
                margin-bottom: 5px;
                display: grid;
                border-bottom: 1px solid rgba(0, 0, 0, 0.493);
                padding-bottom: 2px;
                margin-bottom: 10px;
            }

            & .content {
                display: grid;
                gap: 5px;

                & .info {
                    font-size: getFontSize('standard');

                    &.error {
                        color: getPrimaryColor('destructive');
                        font-weight: bold;
                    }
                }
            }
        }
    }
}