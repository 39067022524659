@use './../Button/Button' as button;

.SimpleForm {
    display: grid;
    padding: 15px 0 15px 0;
    width: 100%;
    color: getTextColor('primary');

    & img {
        max-width: 100%;
    }

    & .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid getBorderColor('standard');

        & .title {
            font-weight: bold;
            font-size: getFontSize('large');
            text-shadow: 0.1em 0.1em 0.2em black;
            color: getPrimaryColor('popping');
        }

        & nav {
            justify-self: end;
            display: flex;
            gap: 5px;
        }
    }

    & .container {
        display: grid;
        gap: 5px;

        & .item {
            display: flex;
            justify-content: space-between;
            gap: 50px;

            &.nav {
                justify-content: end;
                display: flex;
                gap: 5px;
            }

            & .value {

                & .image {
                    display: grid;
                    justify-items: center;
                    gap: 5px;

                    & .warning {
                        color: red;
                    }

                    label {
                        @include button;
                    }

                    & img {
                        max-width: 150px;
                        max-height: 200px;
                    }
                }
                
                & input, textarea {
                    border-radius: 10px;
                    font-size: getFontSize('standard');
                    min-width: 250px;
                }

                & textarea {
                    max-width: 100%;
                    padding: 5px;
                }
            }

            & input {
                text-align: center;
            }

            @media screen and (max-width: 800px) {
                & {
                    display: grid;
                    gap: 0;

                    justify-content: center;
                    justify-items: center;

                    & textarea {
                        resize: vertical;
                    }
                }
            }
        }

        & .info {
            color: getPrimaryColor('popping');
            font-size: getFontSize('standard');
            margin-top: -5px;
        }
    }
}