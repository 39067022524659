.IconButton {
    background: none;
    border: none;
    outline: none;

    display: flex;
    margin: 0;
    padding: 0;

    &:active {
        box-shadow: none !important;
    }
}