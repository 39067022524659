.CookieBanner {
    position: absolute;
    margin-bottom: 100px;
    width: 100%;
    bottom: 0;

    display: flex;
    justify-content: center;

    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 80%;
       
        color: white;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid getBorderColor('standard');
        margin: 0 10px;
        background: getBackgroundColor('secondary');

        & a {
            color: white;
        }
    }
}