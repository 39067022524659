.Overlay {
    position: fixed;
    background: getBackgroundColor('primary');
    width: 100%;
    height: 100%;
    z-index: 99;
    opacity: 0;

    display: grid;
    grid-template-rows: auto 1fr;

    & .toggle-overlay {
        padding: 5px;

        & .Button {
            padding: 5px;
        }
    }

    & .content {
        display: grid;
        overflow-y: auto;
    }

    @media screen and (min-width: 800px) {
        & {
            display: none;
        }
    }
}