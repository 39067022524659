.Modal {
    display: grid;
    gap: 5px;
    color: getTextColor('primary');
    background: getBackgroundColor('secondary');
    z-index: 10;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid getBorderColor('standard');
    box-shadow: getBoxShadow('outer');

    & .body {
        max-height: 75vh;
        overflow-x: hidden;
        overflow-y: auto;
    }

    & input, & textarea {
        border-radius: 10px;
    }

    & .headline, & .message, & .options {
        color: getTextColor('primary');
    }

    & .safety-message {
        color: getPrimaryColor('popping');
        margin-top: 10px;
        text-align: center;
    }

    & .headline {
        display: flex;
        font-size: getFontSize('large');
        font-weight: bold;
        text-align: center;
        align-items: center;
        gap: 10px;
    }

    & .message {
        text-align: center;
    }

    & .safety-prompt {
        display: flex;
        justify-content: center;
        
        & input {
            width: 100%;
            text-align: center;
        }
    }

    & .options {
        display: flex;
        justify-content: space-between;
    }
}