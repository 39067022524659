@import './components/GeneralInfo/GeneralInfo';
@import './components/VenueStats/VenueStats';
@import './components/Profile/Profile';
@import './components/ModalManager/ModalManager';
@import './components/NotificationManager/NotificationManager';
@import './components/Management/Management';
@import './components/Sidebar/Sidebar';
@import './components/Navigation/Navigation';
@import './components/Overlay/Overlay';

.Dashboard {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    
    & .environment-notice {
        background: yellow;

        & .text {
            padding: 2px 5px;
        }
    }

    & .kofi-link {
        & img {
            height: 30px;
        }
    }

    & .wrapper {
        background: getBackgroundColor("secondary");
        color: getTextColor("primary");
        display: grid;
        grid-template-columns: auto 1fr;
        
        & main {
            padding: 10px;
            border-radius: 0 5px 5px 0;
            display: grid;
            grid-gap: getPadding("standard");
            grid-template-rows: min-content auto;
            overflow-y: auto;

            & section {
                background: getBackground("primary");
            }
        }
    }
}