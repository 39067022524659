.MultiToggleOption {
    background: getMultiToggleColor('inactive');
    color: getTextColor('primary');
    font-size: getFontSize('standard');
    padding: 3px 8px;
    border: 1px solid black;
    font-size: getFontSize('standard');
    position: relative;

    display: flex;
    gap: 10px;
    align-items: center;

    &.active {
        background: getMultiToggleColor('active');
    }

    &.large {
        font-size: getFontSize('large');
        padding: 5px 10px;
    }

    &:first-child:last-child {
        border-radius: 10px; 
    }

    &:first-child {
        border-radius: 10px 0 0 10px; 
    }

    &:last-child {
        border-radius: 0 10px 10px 0; 
    }

    &:hover {
        filter: none;
    }
}