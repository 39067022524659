.PermissionsTable {
    color: getTextColor('primary');

    & table {
        border-collapse: collapse;

        & th {
            padding: 0 5px;
        }

        & td {
            border: 1px solid getPrimaryColor('popping');
            padding: 5px;

            text-align: center;

            &.action {
                text-align: left;
            }
        }
    }
}