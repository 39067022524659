.login-wrapper {   
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;


    & .EnvironmentNotice {
        z-index: 3;
    }

    & .CookieBanner {
        z-index: 3;
    }

    & .video-info {
        z-index: 3;
        padding: 5px;
        color: white;

        & a, & a:visited, & a:focus {
            color: inherit;
        }
    }

    .Login {
        position: relative;
        z-index: 1;

        & .background {
            position: fixed;
            filter: brightness(50%);
            top: 0;
            left: 0;
            overflow: hidden;

            & video {
                z-index: 2;
                object-fit: cover;
                width: 100vw;
                height: 100vh;
            }
        } 

        & .login-box {
            display: grid;
            place-items: center;
            position: relative;
            height: 100%;

            & .form-container {
                display: grid;
                gap: 10px;
                background: getBackgroundColor("secondary");
                color: getTextColor("primary");
                box-shadow: getBoxShadow('outer');
                border: 1px solid getBorderColor('standard');
                border-radius: 5px;
                padding: 10px;

                & .header {
                    border-bottom: 1px solid black;
                    padding-bottom: 5px;
                    text-align: center;
                }

                & form {
                    display: grid;
                    gap: 5px;

                    & label {
                        display: flex;
                        justify-content: space-between;
                    }

                    & input {
                        background: getBackgroundColor('primary');
                        color: white;
                        border: 1px solid getBorderColor('standard');
                    }
                }

                & .footer {
                    border-top: 1px solid black;
                    padding-top: 5px;
                }
            }
        }
    }
}