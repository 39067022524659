.CopyField {
    padding: 3px 5px;
    color: black;
    border: 1px solid getBorderColor('standard');
    background: getPrimaryColor('popping');
    display: flex;
    align-items: center;
    border-radius: 10px;

    & .copy {
        display: flex;
        align-items: center;
        padding-left: 5px;
        margin-left: 5px;
        border-left: 1px solid getBorderColor('standard');
    }
}