@mixin adaptive-grid() {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
    }
}