.FirstCharacterPrompt {
    height: 100vh;
    display: grid;
    place-items: center;

    & .container {
        display : grid;
        gap: 20px;

        & .row {
            display: grid;
            justify-items: center;
        }
        & .headline {
            color: getTextColor('grey');
            font-size: getFontSize('largest');
        }
    }
}