@import './Characters/Characters';
@import './Form/Form';
@import './FirstCharacterPrompt/FirstCharacterPrompt';

.Character {
    & .top-bar, & .addendum-bar {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid getBorderColor('standard');
        margin-top: 5px;
        padding-bottom: 5px;

        & .name {
            font-size: getFontSize('largest');
        }

        & .under-title {
            color: getTextColor('grey');
        }

        & span:last-of-type {
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        & .col {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    & .switch {
        display: flex;
        gap: 5px;
        align-items: center;
    }
}