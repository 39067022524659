.InstructionsEditor {
    //border: 1px solid getBorderColor('standard');
    //border-radius: 10px;
    padding: 5px;
    display: grid;
    gap: 5px;
    
    & .instruction, & .col {
        display: flex;
        gap: 5px;
    }

    & .nav {
        align-items: center;
    }

    & .bottom-nav {
        display: flex;
        justify-content: end;
    }
}