.About {
    display: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    
    color: getTextColor("primary");

    display: grid;
    place-items: center;
    
    & .image {
        grid-area: img;

        & img {
            width: 100%;
        }
    }

    & .wrapper {
        max-width: 1000px;
        display: grid;
        gap: 25px;
        grid-template-areas: 
            'head head head head'
            'devs devs contr contr'
            'thank thank thank thank'
        ;

        & .title {
            font-size: getFontSize('larger');
            font-weight: bold;
            color: getPrimaryColor('popping');
        }

        & .name {
            font-weight: bold;
        }

        & .highlighted {
            color: rgb(173, 0, 0);
        }

        & header {
            grid-area: head;
            padding-bottom: 20px;
            border-bottom: 1px solid getBorderColor('primary');
    
            img {
                float: left;
                width: 200px;
                margin: 0 10px 0 0;
            }
        }

        & #development {
            grid-area: devs;
        }

        & #contributions {
            grid-area: contr;
        }

        & #thanks {
            grid-area: thank;
            padding-top: 20px;
            border-top: 1px solid getBorderColor('primary');
        }

        & .list {
            padding: 0 2px;

            & .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}