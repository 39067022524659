.Sidebar {
    background: getPrimaryColor("constructive-mild");
    border-radius: 5px 0 0 5px;
    border-right: 1px solid getBorderColor('standard');

    display: grid;

    & .toggle-overlay {
        padding: 5px;

        & .Button {
            padding: 5px;
        }
    }

    @media screen and (max-width: 800px) {

        & .Navigation {
            display: none;
        }
    }
}
