.ModalManager {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;

    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, 0.521);

    & .modal-display {
        position: absolute;
    }
}