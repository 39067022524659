.Account {
    width: 100%;

    display: grid;
    justify-items: center;
    background: getBackgroundColor('section');
    margin-top: 15px;
    padding: 15px 0 15px 0;

    & .container {
        display: grid;

        & .item {
            display: flex;
            justify-content: space-between;
            gap: 50px;
        }
    }

    & .info {
        color: getPrimaryColor('popping');
    }
}