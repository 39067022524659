// Common
@import './common/styles';

// Shared styles
@import '../../../shared/themes/default__colors';
@import '../../../shared/themes/default__decorations';
@import '../../../shared/themes/default__fonts';
@import '../../../shared/themes/default__styles';
@import '../../../shared/themes/default__mixins';

// Pages
@import '../pages/Login/Login';
@import '../pages/Dashboard/Dashboard';
@import '../pages/About/About';
@import '../pages/Tools/Tools';
@import '../pages/Legal/Legal';
@import '../pages/PublicSeating/PublicSeating';

// Components
@import './../components/components';

//Global CSS
body {
    margin: 0;
    background: #0a0a0ada;
}

* {
    font-family: 'Nunito', sans-serif;
}

textarea, input {
    border: 1px solid getBorderColor('standard');
    background: getBackgroundColor('primary');
    color: white;

    &:focus {
        outline: 1px solid grey;
    }
}

.inactive {
    background: none;
    pointer-events: none;
    opacity: 0.4;
}

.blurred {
    filter: brightness(0.4);
}

.tooltip {
    font-size: getFontSize('standard');
    text-decoration: underline;

    & .tooltiptext {
        visibility: hidden;
        background-color: black;
        margin-left: 20px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;

        position: absolute;
        z-index: 1;
    }

    &:hover > .tooltiptext {
        visibility: visible;
    }
}

