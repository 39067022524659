.EditCharactersByUser {
    display: grid;
    gap: 5px;

    & .character, & nav {
        justify-content: space-between;
        align-items: center;
        display: flex;
        gap: 5px;
    }
}