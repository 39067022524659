.Menu {
    & .top-nav {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    & .categories {
        @include adaptive-grid();
        gap: 10px;

        & .category {
            & .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid getBorderColor('standard');
                margin-bottom: 5px;

                & .name {
                    color: getPrimaryColor('popping');
                    font-size: getFontSize('larger');
                }

                & nav {
                    display: flex;
                    justify-content: space-between;
                    gap: 5px;
                }
            }
            & .items {
                display: grid;
                gap: 5px;
                
                & .item, & .item nav {
                    display: flex;
                    justify-content: space-between;
                    gap: 5px;
                }
            }
    
        }
    }  
}