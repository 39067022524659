.destructive {background: getPrimaryColor('destructive') !important; color: getTextColor('primary');}
.constructive {background: getPrimaryColor('constructive') !important; color: getTextColor('primary');}
.progressive {background: getPrimaryColor('progressive') !important;}
.informative {background: blue !important;}
.neutral {background: getPrimaryColor('neutral') !important; color: getTextColor('primary');}
.dark {background: getPrimaryColor('dark') !important;}

button, select, option {
    &:hover {
      cursor: pointer;
      filter:brightness(1.2);
    }
  
    &:active {
      box-shadow: inset 0px 1px 4px 1px black !important;
    }
}