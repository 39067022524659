.Sections {
    display: grid;
    justify-content: center;

    & .header {
        & nav {
            display: flex;
        }
    }

    & .section {
        

        & img {
            width: 1000px;
            height: 700px;
        }
    }
}